import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 833.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,833.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M0 4165 l0 -4165 5400 0 5400 0 0 4165 0 4165 -5400 0 -5400 0 0
-4165z m6160 2225 c0 -5 -14 -10 -31 -10 -40 0 -207 -43 -292 -75 -413 -155
-746 -501 -881 -918 -30 -92 -62 -260 -71 -374 l-7 -83 -309 0 -309 0 0 68 c0
177 66 419 166 612 44 83 46 87 126 195 144 196 314 336 530 440 177 84 326
127 508 144 127 12 570 13 570 1z m-72 -855 c2 -22 -1 -83 -8 -135 -65 -559
-459 -1039 -999 -1217 -181 -60 -302 -73 -641 -71 -162 0 -281 3 -265 5 491
63 914 364 1136 805 84 166 149 414 149 566 0 98 -23 92 323 89 l302 -2 3 -40z
m-792 25 c47 0 83 -3 81 -7 -3 -5 -63 -6 -134 -4 -85 3 -116 7 -93 11 19 4 41
6 48 4 8 -2 52 -4 98 -4z m-179 -42 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m250 -90 c-2 -18 -4 -6 -4 27 0 33 2 48 4 33 2 -15 2 -42 0 -60z
m-274 37 c-9 -19 -18 -33 -21 -31 -4 5 26 66 33 66 3 0 -3 -16 -12 -35z m-30
-73 c-3 -12 -9 -22 -14 -22 -5 0 -3 11 3 25 13 30 20 28 11 -3z m-36 -146 c-1
-30 -5 -50 -8 -44 -8 13 -29 -122 -32 -200 0 -34 -4 -56 -8 -50 -12 19 1 144
24 251 12 53 23 97 24 97 1 0 1 -24 0 -54z m294 -47 c-16 -46 -30 -78 -30 -71
-1 17 50 164 55 159 2 -2 -9 -41 -25 -88z m-34 -111 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m-41 -90 l-23 -53 -114 -1 -114 0 105 5 c80 5
104 9 102 19 -1 8 2 11 8 7 6 -4 18 11 28 34 9 22 20 41 24 41 4 0 -4 -24 -16
-52z m-594 -1515 c37 -33 16 -73 -39 -73 -26 0 -36 6 -45 26 -11 23 -10 29 11
45 29 23 49 24 73 2z m-1352 -223 l0 -220 -45 0 -44 0 -3 216 c-2 119 -1 218
1 220 2 2 24 4 48 4 l43 0 0 -220z m370 46 c0 -133 3 -176 13 -185 18 -14 27
-4 27 28 1 33 16 54 57 74 36 19 108 22 147 7 22 -8 26 -7 26 8 0 10 -7 28
-16 40 -18 26 -74 30 -97 8 -18 -19 -89 -25 -95 -8 -6 20 24 58 61 76 74 35
189 8 216 -51 6 -13 11 -78 11 -143 l0 -120 -62 -1 c-35 0 -85 -1 -113 -2 -29
-1 -63 5 -82 15 -30 15 -34 15 -42 1 -12 -23 -78 -10 -110 22 l-25 25 -29 -28
c-26 -25 -35 -27 -133 -30 l-104 -4 0 36 0 35 87 3 c74 3 88 6 91 20 4 19 -14
29 -90 48 -94 24 -126 94 -70 150 31 31 97 44 182 38 43 -3 45 -5 48 -35 l3
-32 -83 -3 c-75 -3 -83 -5 -83 -23 0 -15 11 -22 50 -33 70 -19 105 -33 105
-43 0 -5 5 -9 10 -9 6 0 10 53 10 138 0 76 3 142 7 145 3 4 24 7 45 7 l38 0 0
-174z m2767 158 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-677
-44 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m755 0 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m-1179 -28 c-4 -3 -14 2 -24 12 -16 18 -16 18 6 6 13 -6 21 -14 18 -18z m944
24 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m185 -7 c11 -17 -1 -21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17 -11z m-1210 -17
c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m-1095 -32 c30 16 93
11 126 -9 31 -19 32 -19 60 0 55 36 157 18 186 -34 8 -13 15 -73 18 -150 l5
-127 -42 0 -43 0 0 69 c0 151 -22 201 -83 186 -36 -10 -47 -45 -47 -156 l0
-100 -42 3 -43 3 -5 115 c-5 110 -6 115 -30 127 -28 14 -59 8 -77 -16 -8 -10
-12 -55 -13 -123 l0 -108 -45 0 -45 0 0 158 c0 87 2 161 5 164 3 3 26 1 51 -3
28 -5 53 -5 64 1z m500 -62 l0 -73 21 43 c59 118 239 137 329 36 16 -19 30
-44 30 -54 0 -32 -70 -28 -98 5 -48 55 -122 56 -166 4 -32 -38 -33 -66 -3
-110 18 -27 30 -35 61 -37 54 -5 83 4 108 33 16 19 32 25 60 25 44 0 47 -10
18 -57 -24 -39 -81 -70 -147 -79 -80 -10 -167 38 -199 110 -13 30 -13 29 -14
-36 l0 -68 -45 0 -45 0 0 165 0 165 45 0 45 0 0 -72z m986 36 c18 -14 18 -14
-6 -3 -31 14 -36 19 -24 19 6 0 19 -7 30 -16z m191 10 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m-90 -41 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18
2 -7 2 -21 0 -30z m433 17 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4
10 6 0 11 -4 11 -10z m-220 -25 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8
15 10 15 2 0 4 -7 4 -15z m-510 1 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6
5 11 10 11 6 0 10 -2 10 -4z m757 -48 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7
2 -19 0 -25z m160 6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m134 -11 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m716 -5
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1700 -14 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m50 0 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m971 -29 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7
10 -15 7 -18z m319 -17 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-1485 -10 c-8 -8 -9 -4 -5 13 4 13 8 18 11 10 2 -7 -1 -18 -6 -23z m633 13
c-3 -6 -11 -11 -16 -11 -5 0 -4 6 3 14 14 16 24 13 13 -3z m-584 -18 c-12 -20
-14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m52 2 c-3 -9 -8 -14 -10 -11
-3 3 -2 9 2 15 9 16 15 13 8 -4z m1114 -7 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m-294 -5 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2
18 -5z m-316 -25 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-667
-23 c-19 -14 -36 -25 -39 -25 -10 0 53 49 63 50 6 0 -5 -11 -24 -25z m444 9
c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m574 -9 c-3 -3 -9 2
-12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m193 -8 l-24 -22 19 27 c10 14 21
24 23 22 3 -3 -6 -15 -18 -27z m79 12 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16
0 6 5 7 10 4 6 -3 10 -11 10 -16z m-1383 -5 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m1068 -14 c10 -11 13 -20 7 -20 -6 0 -16 9 -22 20 -6 11
-9 20 -7 20 2 0 12 -9 22 -20z m875 0 c-14 -11 -29 -20 -35 -20 -5 0 1 9 15
20 14 11 30 20 35 20 6 0 -1 -9 -15 -20z m-1073 -16 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z"
          />
          <path
            d="M3823 3180 c-12 -5 -24 -19 -28 -31 -16 -50 83 -81 125 -39 23 23 24
30 8 52 -16 20 -75 31 -105 18z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
